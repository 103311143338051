.dnarn {
  background: #202429;
  width: 100%;
  position: fixed;
  z-index: 100;
}

@media (min-width: 1340px) {
  .dnarn nav {
    max-width: 1260px; /** editable **/
  }
}
@media (min-width: 780px) {
  .dnarn nav {
    width: 90%; /** editable **/
  }
}

.dnarn nav {
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 70px; /** editable **/
}

.dnarn nav .logo span {
  font-size: 20px; /** editable **/
  font-weight: 600; /** editable **/
  letter-spacing: -1px; /** editable **/
  color: #62dafb !important; /** editable **/
  line-height: 40px; /** editable **/
}

.dnarn nav .logo img {
  display: inline-block;
  vertical-align: top; /** editable **/
  padding-right: 5px; /** editable **/
  height: 40px; /** editable **/
}

.dnarn nav .nav-items {
  display: flex;
  flex: 1 1;
  padding: 0 0 0 40px; /** editable **/
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.dnarn nav .nav-items::-webkit-scrollbar {
  display: none;
}

.dnarn nav .nav-items ul {
  display: contents;
}

.dnarn nav .nav-items ul li {
  list-style: none;
  padding: 0 15px; /** editable **/
  color: #fff;
  font-size: 18px; /** editable **/
}

/** move the last list with class 'right' to right adjust **/
.dnarn nav .nav-items li.right {
  margin-left: auto;
}

.dnarn nav .nav-items ul li a {
  color: #fff;
  font-size: 18px; /** editable **/
  font-weight: 500; /** editable **/
  text-decoration: none; /** editable **/
}

.dnarn nav .nav-items ul li a.is-active,
.dnarn nav .nav-items ul li a:hover {
  color: #62dafb; /** editable **/
}
.dnarn nav form {
  display: flex;
  padding: 2px; /** editable **/
  background: #1e232b; /** editable **/
  min-width: 18% !important; /** editable **/
}
.dnarn nav form .search-data {
  width: 100%;
  height: 100%;
  color: #fff; /** editable **/
  font-size: 16px; /** editable **/
  border: none; /** editable **/
  font-weight: 500; /** editable **/
  background: none; /** editable **/
}
.dnarn nav form .search-data i {
  color: #000000;
}

.dnarn nav .menu-icon,
.dnarn nav .cancel-icon,
.dnarn nav .search-icon {
  width: 40px; /** editable **/
  text-align: center; /** editable **/
  margin: 0 50px; /** editable **/
  font-size: 18px; /** editable **/
  color: #fff; /** editable **/
  cursor: pointer;
  display: none;
}
.dnarn nav .menu-icon span,
.dnarn nav .cancel-icon,
.dnarn nav .search-icon {
  display: none;
}

@media (min-width: 780px) {
  .dnarn nav .nav-items {
    mask-image: linear-gradient(
      to right,
      transparent,
      black 20px,
      black 90%,
      transparent
    );
    -webkit-mask-image: linear-gradient(
      to right,
      transparent,
      black 20px,
      black 90%,
      transparent
    );
  }
}

@media (max-width: 779px) {
  .dnarn nav {
    padding: 0px; /** editable **/
  }
  .dnarn nav form .search-data {
    margin: 10px; /** editable **/
  }
  .dnarn nav .logo {
    flex: 2 1;
    text-align: center; /** editable **/
  }

  .dnarn nav .logo img {
    padding-right: 0;
  }

  .dnarn nav .logo span {
    display: none;
  }

  .dnarn nav .nav-items {
    position: fixed;
    z-index: 99;
    top: 70px;
    width: 100%;
    left: -100%;
    height: calc(100vh - 70px);
    overflow: auto;
    margin: 0 auto;
    padding-bottom: 30px; /** leave some space to scroll to see the last item **/
    text-align: left;
    background: #14181f; /** editable **/
    display: inline-block;
    transition: left 0.3s ease; /** editable **/
  }
  .dnarn nav .nav-items.active {
    left: 0px;
  }
  .dnarn nav .nav-items li {
    line-height: 40px; /** editable **/
    margin: 20px 0; /** editable **/
  }
  .dnarn nav .nav-items li a {
    font-size: 20px; /** editable **/
  }

  .dnarn nav form {
    position: absolute;
    top: 70px;
    right: 50px;
    opacity: 0;
    pointer-events: none;
    transition: top 0.3s ease, opacity 0.1s ease;
  }
  .dnarn nav form.active {
    top: 70px;
    opacity: 1;
    pointer-events: auto;
  }
  .dnarn nav .menu-icon {
    display: block;
  }
  .dnarn nav .search-icon,
  .dnarn nav .menu-icon span {
    display: block;
  }
  .dnarn nav .menu-icon span.hide,
  .dnarn nav .search-icon.hide {
    display: none;
  }
  .dnarn nav .cancel-icon.show {
    display: block;
  }
  .dnarn nav .menu-icon,
  .dnarn nav .cancel-icon,
  .dnarn nav .search-icon {
    margin: 0 20px; /** editable **/
  }
  .dnarn nav form {
    left: 0px;
    width: 100%;
    padding: 0 15px; /** editable **/
  }
}
@media (max-width: 350px) {
  .dnarn nav .menu-icon,
  .dnarn nav .cancel-icon,
  .dnarn nav .search-icon {
    margin: 0 10px; /** editable **/
    font-size: 16px; /** editable **/
  }
}

.popup-content{margin:auto;background:#fff;width:50%;padding:5px;border:1px solid #d7d7d7}[role=tooltip].popup-content{width:200px;box-shadow:0 0 3px rgba(0,0,0,.16);border-radius:5px}.popup-overlay{background:rgba(0,0,0,.5)}[data-popup=tooltip].popup-overlay{background:transparent}.popup-arrow{-webkit-filter:drop-shadow(0 -3px 3px rgba(0,0,0,.16));filter:drop-shadow(0 -3px 3px rgba(0,0,0,.16));color:#fff;stroke-width:2px;stroke:#d7d7d7;stroke-dasharray:30px;stroke-dashoffset:-54px;left:0;right:0;top:0;bottom:0}
.sl-heart1,
.sl-heart1 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-heart1 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-heart1 .sl-loader {
    width: 100px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-heart1 .sl-loader-title {
    width: 100%;
    text-align: center;
    font-weight: 700;
    line-height: 1.7;
    font-size: 20px;
    color: var(--cl);
}

.sl-heart1 .sl-loader .sl-loader-items {
    width: 100%;
    height: 100px;
    position: relative;
}

.sl-heart1 .sl-loader .sl-loader-items .sl-heart {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-85%, -75%);
    transform: translate(-85%, -75%);
    background-color: var(--bg);
    -webkit-animation: sl-heart1 1000ms linear infinite;
    animation: sl-heart1 1000ms linear infinite;
}

.sl-heart1 .sl-loader .sl-loader-items .sl-heart::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    left: 30px;
    top: 0;
    background-color: var(--bg);
}

.sl-heart1 .sl-loader .sl-loader-items .sl-heart::before {
    content: "";
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 15px;
    top: 15px;
    background-color: var(--bg);
}

@-webkit-keyframes sl-heart1 {
    to {
        -webkit-transform: translate(-60%, -60%) scale(0.5);
        transform: translate(-60%, -60%) scale(0.5);
    }
    30% {
        -webkit-transform: translate(-80%, -70%) scale(0.8);
        transform: translate(-80%, -70%) scale(0.8);
    }
    60% {
        -webkit-transform: translate(-60%, -60%) scale(0.5);
        transform: translate(-60%, -60%) scale(0.5);
    }
    80% {
        -webkit-transform: translate(-80%, -70%) scale(0.8);
        transform: translate(-80%, -70%) scale(0.8);
    }
    from {
        -webkit-transform: translate(-85%, -75%) scale(1);
        transform: translate(-85%, -75%) scale(1);
    }
}

@keyframes sl-heart1 {
    to {
        -webkit-transform: translate(-60%, -60%) scale(0.5);
        transform: translate(-60%, -60%) scale(0.5);
    }
    30% {
        -webkit-transform: translate(-80%, -70%) scale(0.8);
        transform: translate(-80%, -70%) scale(0.8);
    }
    60% {
        -webkit-transform: translate(-60%, -60%) scale(0.5);
        transform: translate(-60%, -60%) scale(0.5);
    }
    80% {
        -webkit-transform: translate(-80%, -70%) scale(0.8);
        transform: translate(-80%, -70%) scale(0.8);
    }
    from {
        -webkit-transform: translate(-85%, -75%) scale(1);
        transform: translate(-85%, -75%) scale(1);
    }
}
.sl-box1,
.sl-box1 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-box1 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-box1 .sl-loader-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.7;
    color: var(--cl);
}

.sl-box1 .sl-loader {
    width: 100px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-box1 .sl-loader .sl-loader-items {
    width: 100%;
    height: 100px;
    -webkit-perspective: 100px;
    perspective: 100px;
}

.sl-box1 .sl-loader .sl-loader-items .sl-parent-cub {
    width: 100px;
    height: 100px;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 50% 50% -50px;
    transform-origin: 50% 50% -50px;
}

.sl-box1 .sl-loader .sl-loader-items .sl-parent-cub .sl-cub {
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: var(--bg);
    -webkit-animation: sl-box1 500ms ease infinite;
    animation: sl-box1 500ms ease infinite;
}

@-webkit-keyframes sl-box1 {
    to {
        -webkit-transform: translate(-50%, -50%) rotateX(0deg);
        transform: translate(-50%, -50%) rotateX(0deg);
    }
    from {
        -webkit-transform: translate(-50%, -50%) rotateX(180deg);
        transform: translate(-50%, -50%) rotateX(180deg);
    }
}

@keyframes sl-box1 {
    to {
        -webkit-transform: translate(-50%, -50%) rotateX(0deg);
        transform: translate(-50%, -50%) rotateX(0deg);
    }
    from {
        -webkit-transform: translate(-50%, -50%) rotateX(180deg);
        transform: translate(-50%, -50%) rotateX(180deg);
    }
}
.sl-box2,
.sl-box2 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-box2 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-box2 .sl-loader-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.7;
    color: var(--cl);
}

.sl-box2 .sl-loader {
    width: 100px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-box2 .sl-loader .sl-loader-items {
    width: 100%;
    height: 100px;
    -webkit-perspective: 100px;
    perspective: 100px;
}

.sl-box2 .sl-loader .sl-loader-items .sl-parent-cub {
    width: 100px;
    height: 100px;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 50% 50% -50px;
    transform-origin: 50% 50% -50px;
}

.sl-box2 .sl-loader .sl-loader-items .sl-parent-cub .sl-cub {
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: var(--bg);
    -webkit-animation: sl-box2 500ms ease infinite;
    animation: sl-box2 500ms ease infinite;
}

@-webkit-keyframes sl-box2 {
    to {
        -webkit-transform: translate(-50%, -50%) rotateY(0deg);
        transform: translate(-50%, -50%) rotateY(0deg);
    }
    from {
        -webkit-transform: translate(-50%, -50%) rotateY(180deg);
        transform: translate(-50%, -50%) rotateY(180deg);
    }
}

@keyframes sl-box2 {
    to {
        -webkit-transform: translate(-50%, -50%) rotateY(0deg);
        transform: translate(-50%, -50%) rotateY(0deg);
    }
    from {
        -webkit-transform: translate(-50%, -50%) rotateY(180deg);
        transform: translate(-50%, -50%) rotateY(180deg);
    }
}
.sl-box3,
.sl-box3 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-box3 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-box3 .sl-loader-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.7;
    color: var(--cl);
}

.sl-box3 .sl-loader {
    width: 100px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-box3 .sl-loader .sl-loader-items {
    width: 100%;
    height: 100px;
    -webkit-perspective: 100px;
    perspective: 100px;
}

.sl-box3 .sl-loader .sl-loader-items .sl-parent-cub {
    width: 100px;
    height: 100px;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 50% 50% -50px;
    transform-origin: 50% 50% -50px;
}

.sl-box3 .sl-loader .sl-loader-items .sl-parent-cub .sl-cub {
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: var(--bg);
    -webkit-animation: sl-box3 500ms ease infinite;
    animation: sl-box3 500ms ease infinite;
}

@-webkit-keyframes sl-box3 {
    to {
        -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
        transform: translate(-50%, -50%) rotateZ(0deg);
    }
    from {
        -webkit-transform: translate(-50%, -50%) rotateZ(180deg);
        transform: translate(-50%, -50%) rotateZ(180deg);
    }
}

@keyframes sl-box3 {
    to {
        -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
        transform: translate(-50%, -50%) rotateZ(0deg);
    }
    from {
        -webkit-transform: translate(-50%, -50%) rotateZ(180deg);
        transform: translate(-50%, -50%) rotateZ(180deg);
    }
}
.sl-box4,
.sl-box4 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-box4 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-box4 .sl-loader-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.7;
    color: var(--cl);
}

.sl-box4 .sl-loader {
    width: 100px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-box4 .sl-loader .sl-loader-items {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.sl-box4 .sl-loader .sl-loader-items .sl-rectangular {
    width: 10px;
    height: 25px;
    background-color: var(--bg);
    border-radius: 5px;
    -webkit-animation: sl-box4 600ms ease-in-out infinite;
    animation: sl-box4 600ms ease-in-out infinite;
    -webkit-animation-delay: calc(var(--i) * -650ms);
    animation-delay: calc(var(--i) * -650ms);
}

@-webkit-keyframes sl-box4 {
    from {
        height: 30px;
    }
    50% {
        height: 60px;
    }
    to {
        height: 30px;
    }
}

@keyframes sl-box4 {
    from {
        height: 30px;
    }
    50% {
        height: 60px;
    }
    to {
        height: 30px;
    }
}
.sl-box5,
.sl-box5 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-box5 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-box5 .sl-loader-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.7;
    color: var(--cl);
}

.sl-box5 .sl-loader {
    width: 100px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-box5 .sl-loader .sl-loader-items {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.sl-box5 .sl-loader .sl-loader-items .sl-rectangular-ping {
    width: 100%;
    height: 5px;
    position: relative;
    background-color: var(--bg);
    overflow: hidden;
}

.sl-box5 .sl-loader .sl-loader-items .sl-rectangular-ping::before {
    content: '';
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: var(--bgb);
    -webkit-animation: sl-rectangular-ping 800ms ease-in infinite;
    animation: sl-rectangular-ping 800ms ease-in infinite;
}

@-webkit-keyframes sl-rectangular-ping {
    from {
        left: -10px;
    }
    to {
        left: calc(100% + 10px);
    }
}

@keyframes sl-rectangular-ping {
    from {
        left: -10px;
    }
    to {
        left: calc(100% + 10px);
    }
}
.sl-box6,
.sl-box6 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-box6 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-box6 .sl-loader {
    width: 100px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-box6 .sl-loader-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: var(--cl);
    font-weight: 700;
    line-height: 1.7;
}

.sl-box6 .sl-loader .sl-loader-items {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.sl-box6 .sl-loader .sl-loader-items .sl-item {
    width: 10px;
    height: 10px;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    position: relative;
    box-shadow: 0 0 5px 0 var(--shadow);
    background-color: var(--bga);
    -webkit-animation: sl-box6 2500ms ease-in infinite;
    animation: sl-box6 2500ms ease-in infinite;
    -webkit-animation-delay: calc(var(--i) * 500ms);
    animation-delay: calc(var(--i) * 500ms);
}

@-webkit-keyframes sl-box6 {
    from {
        top: 0;
        background-color: var(--bga);
        box-shadow: 0 0 5px 0 var(--shadow);
    }
    10% {
        top: -10px;
        background-color: var(--bg);
        box-shadow: 0 0 2px var(--shadow);
    }
    12.5% {
        top: -10px;
        background-color: var(--bg);
        box-shadow: 0 0 2px var(--shadow);
    }
    22.5% {
        top: 0;
        background-color: var(--bga);
        box-shadow: 0 0 5px 0 var(--shadow);
    }
    to {
        top: 0;
        background-color: var(--bga);
        box-shadow: 0 0 5px 0 var(--shadow);
    }
}

@keyframes sl-box6 {
    from {
        top: 0;
        background-color: var(--bga);
        box-shadow: 0 0 5px 0 var(--shadow);
    }
    10% {
        top: -10px;
        background-color: var(--bg);
        box-shadow: 0 0 2px var(--shadow);
    }
    12.5% {
        top: -10px;
        background-color: var(--bg);
        box-shadow: 0 0 2px var(--shadow);
    }
    22.5% {
        top: 0;
        background-color: var(--bga);
        box-shadow: 0 0 5px 0 var(--shadow);
    }
    to {
        top: 0;
        background-color: var(--bga);
        box-shadow: 0 0 5px 0 var(--shadow);
    }
}
.sl-bubble1,
.sl-bubble1 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-bubble1 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-bubble1 .sl-loader {
    width: 150px;
    height: 40px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-bubble1 .sl-loader-title {
    padding-top: 10px;
    width: 100%;
    color: var(--cl);
    font-weight: 700;
    font-size: 20px;
    line-height: 1.7;
    text-align: center;
}

.sl-bubble1  .sl-loader-items {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.sl-bubble1  .sl-loader-items .sl-bubble {
    width: 30px;
    height: 30px;
    background-color: var(--bg);
    border-radius: 100%;
    -webkit-animation: sl-bubble1 1200ms ease-in-out infinite;
    animation: sl-bubble1 1200ms ease-in-out infinite;
    -webkit-animation-delay: calc(var(--i) * -350ms);
    animation-delay: calc(var(--i) * -350ms);
}

@-webkit-keyframes sl-bubble1 {
    to {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50% {
        -webkit-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3);
    }
    from {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes sl-bubble1 {
    to {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50% {
        -webkit-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3);
    }
    from {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}
.sl-bubble2,
.sl-bubble2 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-bubble2 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-bubble2 .sl-loader {
    width: 150px;
    height: 40px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-bubble2 .sl-loader-title {
    padding-top: 10px;
    width: 100%;
    color: var(--cl);
    font-weight: 700;
    font-size: 20px;
    line-height: 1.7;
    text-align: center;
}

.sl-bubble2 .sl-loader .sl-loader-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.sl-bubble2 .sl-loader .sl-loader-items .sl-bubble {
    width: 30px;
    height: 30px;
    background-color: var(--bg);
    border-radius: 100%;
    -webkit-animation: sl-bubble2 1200ms ease-in-out infinite;
    animation: sl-bubble2 1200ms ease-in-out infinite;
    -webkit-animation-delay: calc(var(--i) * -350ms);
    animation-delay: calc(var(--i) * -350ms);
}

@-webkit-keyframes sl-bubble2 {
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    from {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes sl-bubble2 {
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    from {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
.sl-bubble3,
.sl-bubble3 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-bubble3 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-bubble3 .sl-loader {
    width: 100px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-bubble3 .sl-loader-title {
    padding-top: 10px;
    width: 100%;
    color: var(--cl);
    font-weight: 700;
    font-size: 20px;
    line-height: 1.7;
    text-align: center;
}

.sl-bubble3 .sl-loader .sl-loader-items {
    width: 100%;
    height: 100px;
    position: relative;
}

.sl-bubble3 .sl-loader .sl-loader-items .sl-bubble {
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: var(--bg);
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: sl-bubble3 1200ms linear infinite;
    animation: sl-bubble3 1200ms linear infinite;
    -webkit-animation-delay: calc(var(--i) * -400ms);
    animation-delay: calc(var(--i) * -400ms);
}

@-webkit-keyframes sl-bubble3 {
    from {
        width: 15px;
        height: 15px;
        opacity: 1;
    }
    to {
        width: 80px;
        height: 80px;
        opacity: 0;
    }
}

@keyframes sl-bubble3 {
    from {
        width: 15px;
        height: 15px;
        opacity: 1;
    }
    to {
        width: 80px;
        height: 80px;
        opacity: 0;
    }
}
.sl-bubble4,
.sl-bubble4 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-bubble4 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-bubble4 .sl-loader {
    width: 100px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-bubble4 .sl-loader-title {
    padding-top: 10px;
    width: 100%;
    color: var(--cl);
    font-weight: 700;
    font-size: 20px;
    line-height: 1.7;
    text-align: center;
}

.sl-bubble4 .sl-loader .sl-loader-items {
    width: 100%;
    height: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.sl-bubble4 .sl-loader .sl-loader-items .sl-item-content-loader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 70px;
    -webkit-animation: sl-bubble4 1.5s ease-in infinite;
    animation: sl-bubble4 1.5s ease-in infinite;
}

.sl-bubble4 .sl-loader .sl-loader-items .sl-item-content-loader .sl-circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--bg);
}

@-webkit-keyframes sl-bubble4 {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    45% {
        -webkit-transform: rotate(400deg);
        transform: rotate(400deg);
    }
    50% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    95% {
        -webkit-transform: rotate(-40deg);
        transform: rotate(-40deg);
    }
    to {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

@keyframes sl-bubble4 {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    45% {
        -webkit-transform: rotate(400deg);
        transform: rotate(400deg);
    }
    50% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    95% {
        -webkit-transform: rotate(-40deg);
        transform: rotate(-40deg);
    }
    to {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}
.sl-bubble5,
.sl-bubble5 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-bubble5 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-bubble5 .sl-loader {
    width: 100px;
    position: relative;
    height: 35px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-bubble5 .sl-loader-title {
    padding-top: 10px;
    width: 100%;
    color: var(--cl);
    font-weight: 700;
    font-size: 20px;
    line-height: 1.7;
    text-align: center;
}

.sl-bubble5 .sl-loader .sl-loader-item {
    width: 20px;
    height: 20px;
    background-color: var(--bg);
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 50%;
}

.sl-bubble5 .sl-loader .sl-loader-item:nth-child(2) {
    -webkit-animation: sl-bubble5AnimOne 600ms linear infinite;
    animation: sl-bubble5AnimOne 600ms linear infinite;
  }
  
.sl-bubble5 .sl-loader .sl-loader-item:nth-child(3) {
    -webkit-animation: sl-bubble5AnimTwo 600ms linear infinite;
    animation: sl-bubble5AnimTwo 600ms linear infinite;
}

.sl-bubble5 .sl-loader .sl-loader-item:first-child {
    -webkit-animation: sl-bubble5AnimThere 600ms linear infinite;
    animation: sl-bubble5AnimThere 600ms linear infinite;
}

.sl-bubble5 .sl-loader .sl-loader-item:last-child {
    -webkit-animation: sl-bubble5AnimFour 600ms linear infinite;
    animation: sl-bubble5AnimFour 600ms linear infinite;
}
  
@-webkit-keyframes sl-bubble5AnimOne {
    0% {
        -webkit-transform: translate(7px, -50%) scale(1);
        transform: translate(7px, -50%) scale(1);
    }
    100% {
        -webkit-transform: translate(41px, -50%) scale(1.5);
        transform: translate(41px, -50%) scale(1.5);
    }
}

@keyframes sl-bubble5AnimOne {
    0% {
        -webkit-transform: translate(7px, -50%) scale(1);
        transform: translate(7px, -50%) scale(1);
    }
    100% {
        -webkit-transform: translate(41px, -50%) scale(1.5);
        transform: translate(41px, -50%) scale(1.5);
    }
}

@-webkit-keyframes sl-bubble5AnimTwo {
    0% {
        -webkit-transform: translate(41px, -50%) scale(1.5);
        transform: translate(41px, -50%) scale(1.5);
    }
    100% {
        -webkit-transform: translate(74px, -50%) scale(1);
        transform: translate(74px, -50%) scale(1);
    }
}

@keyframes sl-bubble5AnimTwo {
    0% {
        -webkit-transform: translate(41px, -50%) scale(1.5);
        transform: translate(41px, -50%) scale(1.5);
    }
    100% {
        -webkit-transform: translate(74px, -50%) scale(1);
        transform: translate(74px, -50%) scale(1);
    }
}

@-webkit-keyframes sl-bubble5AnimThere {
    0% {
        -webkit-transform: translate(7px, -50%) scale(0.5);
        transform: translate(7px, -50%) scale(0.5);
    }
    100% {
        -webkit-transform: translate(7px, -50%) scale(1);
        transform: translate(7px, -50%) scale(1);
    }
}

@keyframes sl-bubble5AnimThere {
    0% {
        -webkit-transform: translate(7px, -50%) scale(0.5);
        transform: translate(7px, -50%) scale(0.5);
    }
    100% {
        -webkit-transform: translate(7px, -50%) scale(1);
        transform: translate(7px, -50%) scale(1);
    }
}

@-webkit-keyframes sl-bubble5AnimFour {
    0% {
        -webkit-transform: translate(74px, -50%) scale(1);
                transform: translate(74px, -50%) scale(1);
    }
    100% {
        -webkit-transform: translate(74px, -50%) scale(0.5);
                transform: translate(74px, -50%) scale(0.5);
    }
}

@keyframes sl-bubble5AnimFour {
    0% {
        -webkit-transform: translate(74px, -50%) scale(1);
                transform: translate(74px, -50%) scale(1);
    }
    100% {
        -webkit-transform: translate(74px, -50%) scale(0.5);
                transform: translate(74px, -50%) scale(0.5);
    }
}
.sl-spinner1,
.sl-spinner1 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-spinner1 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-spinner1 .sl-loader {
    width: 100px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-spinner1 .sl-loader-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 1.7;
    font-weight: 700;
    color: var(--cl);
}

.sl-spinner1 .sl-loader .sl-loader-items {
    width: 100%;
    height: 100px;
    position: relative;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.sl-spinner1 .sl-loader .sl-loader-items .sl-item {
    -webkit-transform: rotate(calc(var(--i) * 30deg));
    transform: rotate(calc(var(--i) * 30deg));
    -webkit-animation: sl-spinner1 1.2s linear infinite;
    animation: sl-spinner1 1.2s linear infinite;
    -webkit-animation-delay: calc(var(--i) * -100ms);
    animation-delay: calc(var(--i) * -100ms);
    -webkit-transform-origin: 50px 50px;
    transform-origin: 50px 50px;
}

.sl-spinner1 .sl-loader .sl-loader-items .sl-item::after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 47px;
    width: 7px;
    height: 21px;
    background-color: var(--bg);
    border-radius: 20%;
    box-shadow: -1px 1px 2px 1px rgba(0, 0, 0, 0.2);
}

@-webkit-keyframes sl-spinner1 {
    to {
        opacity: 0;
    }
    from {
        opacity: 1;
    }
}

@keyframes sl-spinner1 {
    to {
        opacity: 0;
    }
    from {
        opacity: 1;
    }
}
.sl-spinner2,
.sl-spinner2 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-spinner2 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-spinner2 .sl-loader {
    width: 100px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-spinner2 .sl-loader-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 1.7;
    font-weight: 700;
    color: var(--cl);
}

.sl-spinner2 .sl-loader-items {
    width: 100%;
    height: 100px;
    position: relative;
}

.sl-spinner2 .sl-loader .sl-loader-items .sl-item {
    -webkit-transform: rotate(calc(var(--i) * 45deg));
    transform: rotate(calc(var(--i) * 45deg));
    -webkit-transform-origin: 50px 50px;
    transform-origin: 50px 50px;
}

.sl-spinner2 .sl-loader .sl-loader-items .sl-item::after {
    content: "";
    display: block;
    position: absolute;
    top: 25px;
    left: 25px;
    width: 10px;
    height: 10px;
    background-color: var(--bg);
    border-radius: 50%;
    -webkit-animation: sl-spinner2 800ms linear infinite;
    animation: sl-spinner2 800ms linear infinite;
    -webkit-animation-delay: calc(var(--i) * 100ms);
    animation-delay: calc(var(--i) * 100ms);
}

@-webkit-keyframes sl-spinner2 {
    to {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    from {
        -webkit-transform: scale(1.5, 1.5);
        transform: scale(1.5, 1.5);
    }
}

@keyframes sl-spinner2 {
    to {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    from {
        -webkit-transform: scale(1.5, 1.5);
        transform: scale(1.5, 1.5);
    }
}
.sl-spinner3,
.sl-spinner3 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-spinner3 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-spinner3 .sl-loader {
    width: 100px;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-spinner3 .sl-loader-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.7;
    color: var(--cl);
}

.sl-spinner3 .sl-loader .sl-loader-parent {
    width: 100%;
    height: 100px;
    position: relative;
}

.sl-spinner3 .sl-loader .sl-loader-parent .sl-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: 10px solid var(--border);
    border-top: 10px solid var(--bg);
    -webkit-animation: sl-spinner3 800ms linear infinite;
    animation: sl-spinner3 800ms linear infinite;
}

@-webkit-keyframes sl-spinner3 {
    to {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
        transform: translate(-50%, -50%) rotate(360deg);
    }
    from {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
    }
}

@keyframes sl-spinner3 {
    to {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
        transform: translate(-50%, -50%) rotate(360deg);
    }
    from {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
    }
}
.sl-ekvalayzer,
.sl-ekvalayzer * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-ekvalayzer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-ekvalayzer .sl-loader-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.7;
    color: var(--cl);
}

.sl-ekvalayzer .sl-loader-items {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-ekvalayzer .sl-loader-items .sl-parent-rectangular {
    padding: 3px;
    width: -webkit-min-content;
    width: min-content;
    height: -webkit-min-content;
    height: min-content;
}

.sl-ekvalayzer .sl-loader-items .sl-parent-rectangular .sl-rectangular {
    width: 10px;
    background-color: var(--bg);
    box-shadow: 0 0 10px 2px var(--bg);
    -webkit-animation: sl-ekvalayzer1 500ms ease-in-out infinite;
    animation: sl-ekvalayzer1 500ms ease-in-out infinite;
    -webkit-animation-delay: calc(var(--i) * -100ms);
    animation-delay: calc(var(--i) * -100ms);
}

@-webkit-keyframes sl-ekvalayzer1 {
    from {
        height: calc(var(--w) * 20px);
    }
    50% {
        height: 80px;
    }
    to {
        height: calc(var(--w) * 20px);
    }
}

@keyframes sl-ekvalayzer1 {
    from {
        height: calc(var(--w) * 20px);
    }
    50% {
        height: 80px;
    }
    to {
        height: calc(var(--w) * 20px);
    }
}
.sl-hourglass,
.sl-hourglass * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sl-hourglass {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.sl-hourglass .sl-hourglass-loader-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: var(--cl);
    font-weight: 700;
    line-height: 1.7;
}

.sl-hourglass .sl-hourglass-loader-items {
    width: 100%;
    height: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    -webkit-transform: scale(var(--size));
    transform: scale(var(--size));
}

.sl-hourglass .sl-hourglass-loader-items .sl-hourglass-item {
    border: 30px solid transparent;
    border-top-color: var(--bg);
    border-bottom-color: var(--bg);
    border-radius: 50%;
    -webkit-animation: sl-hourglass 1.2s ease-in infinite;
    animation: sl-hourglass 1.2s ease-in infinite;
}

@-webkit-keyframes sl-hourglass {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    45% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    80% {
        -webkit-transform: rotate(900deg);
        transform: rotate(900deg);
    }
    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@keyframes sl-hourglass {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    45% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    80% {
        -webkit-transform: rotate(900deg);
        transform: rotate(900deg);
    }
    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}
.alice-carousel .animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.alice-carousel .animated-out {
  z-index: 1;
}

.alice-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.alice-carousel {
  position: relative;
  width: 100%;
  margin: auto;
  direction: ltr;
}

.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  vertical-align: top;
  white-space: normal;
  line-height: 0;
}
.alice-carousel__stage-item * {
  line-height: normal;
  line-height: initial;
}
.alice-carousel__stage-item.__hidden {
  opacity: 0;
  overflow: hidden;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 10px 5px;
}
.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  position: relative;
  content: attr(data-area);
  text-transform: capitalize;
}

.alice-carousel__prev-btn {
  text-align: right;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  color: #465798;
}
.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: darkred;
}
.alice-carousel__prev-btn-item.__inactive,
.alice-carousel__next-btn-item.__inactive {
  opacity: 0.4;
  pointer-events: none;
}

.alice-carousel__play-btn {
  position: absolute;
  top: 30px;
  left: 20px;
  display: inline-block;
}
.alice-carousel__play-btn:hover {
  cursor: pointer;
}
.alice-carousel__play-btn-wrapper {
  position: relative;
  width: 32px;
  height: 32px;
  padding: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.alice-carousel__play-btn-item {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: transparent;
}
.alice-carousel__play-btn-item::before, .alice-carousel__play-btn-item::after {
  position: absolute;
  pointer-events: none;
  display: block;
  width: 0;
  height: 0;
  content: "";
  transition: all 0.4s linear;
  border-width: 8px 0 8px 15px;
  border-style: solid;
  border-color: transparent;
  border-left-color: #465798;
}
.alice-carousel__play-btn-item::before {
  left: 5px;
  height: 14px;
}
.alice-carousel__play-btn-item::after {
  top: 7px;
  left: 18px;
}
.alice-carousel__play-btn-item.__pause::before, .alice-carousel__play-btn-item.__pause::after {
  height: 30px;
  border-width: 0 0 0 10px;
}
.alice-carousel__play-btn-item.__pause::after {
  top: 0;
  left: 18px;
}

.alice-carousel__dots {
  margin: 30px 3px 5px;
  padding: 0;
  list-style: none;
  text-align: center;
}
.alice-carousel__dots > li {
  display: inline-block;
}
.alice-carousel__dots-item:not(.__custom) {
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #e0e4fb;
}
.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 20px;
}
.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
  background-color: #6e7ebc;
}

.alice-carousel__slide-info {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 5px 10px;
  color: #465798;
  border-radius: 5px;
  background-color: rgba(224, 228, 251, 0.6);
}
.alice-carousel__slide-info-item {
  vertical-align: middle;
  line-height: 0;
}
.rb-tabs,.rb-tabs .rb-tabs-header{position:relative}.rb-tabs .rb-tabs-header .rb-tabs-items{display:flex;align-items:center;list-style:none;padding:0;margin:0;border-bottom:1px solid #ebebeb}@media (max-width:768px){.rb-tabs .rb-tabs-header .rb-tabs-items{justify-content:space-between}}.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item{padding:8px 16px;cursor:pointer}.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item.active{font-weight:500}@media (max-width:768px){.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item{font-size:14px}}.rb-tabs .rb-tabs-header .rb-tabs-active-item{height:2px;background-color:#222;position:absolute;bottom:0;transition:all .3s cubic-bezier(.4,0,.2,1) 0ms;will-change:left,width}.rb-tabs .rb-tabs-content .rb-tabs-content-item{display:none}.rb-tabs .rb-tabs-content .rb-tabs-content-item.show{display:block}
